import { ContentInterface } from '@/types'

enum Speeds {
  DEFAULT = 0,
  MORE_TEXT = 3,
  LESS_TEXT = -10,
}

const contact = {
  content: `
    My email address is <strong>katie.ella.adams@gmail</strong>.
  I have a blog you can find on <a href="https://dev.to/katieadamsdev">Dev.to</a>
    You can also find me on <a href="https://www.linkedin.com/in/kitella1/">LinkedIn</a>.
    <br/>
    <br/>
    I look forward to hearing from you!
    `,
  options: {
    speed: Speeds.LESS_TEXT,
  },
  sortOrder: 25,
} as ContentInterface

const home = {
  content: `
        <h1>Hello World!</h1>
        <img src="https://a.storyblok.com/f/111415/1288x1438/af2f7c0ee8/selfie-latest.jpg" alt="A picture of me facing the camera, wearing a beanie." /><br />
        <h2><i>She/They</i></h2><br/>
        My name's Katie and I write code. Although I'm a hugely capable web developer by trade - who loves Vue.js - I'll try anything once! I'm based in Newcastle-upon-Tyne, UK, and I'm open for my next opportunity.

        Fun facts about me: 
        <ul>
          <li>Favourite dinosaur is an Iguanadon</li>
          <li>My go-to D&D 5e class is a Monk</li>
          <li>I can speak Spanish at a basic level</li>
          <li>Divinity: Original Sin 2 is my most-played game on Steam with 298.3 hours</li>
          <li>Stardew Valley is my favourite game</li>
          <li>I own 3 guitars</li>
        </ul>
        <svg class="divider">
          <line x1="0" y1="0" x2="100%" y2="0" />
        </svg>
        Interact with the site's content using the terminal commands below. For example, you can type 'hobbies' to see what I've been up to lately. If you don't fancy guesswork, type 'help' to see the full list.
      `,
  options: {
    speed: Speeds.MORE_TEXT,
  },
  sortOrder: 0,
} as ContentInterface

const education = {
  content: `
    <h2>Staffordshire University</h2>
    <i>2016 – 2020</i>
    <svg class="divider">
          <line x1="0" y1="0" x2="100%" y2="0" />
        </svg>
    BSc (Hons) Computing Science
    Achieved a First with Honours
    <br />
    <br />
    <h2>Worcester Sixth Form College</h2>
    <i>2014 – 2016</i>
    <svg class="divider">
          <line x1="0" y1="0" x2="100%" y2="0" />
        </svg>
    A-Levels in Computing, Drama, and Music Tech
    AS-Level in Spanish
    <br />
    <br />
    <h2>Christopher Whitehead Language College</h2>
    <i>2009 - 2014</i>
    <svg class="divider">
          <line x1="0" y1="0" x2="100%" y2="0" />
        </svg>
    11 GCSEs including Maths, English, Science, ICT, and Spanish
      `,
  options: {
    speed: Speeds.MORE_TEXT,
  },
  sortOrder: 10,
} as ContentInterface

const employment = {
  content: `
    <h2>Greggs, Senior Platform Developer</h2>
    <i>Jan 2024 - Present</i>
    <hr />
    Collaborated with development teams to identify processes in need of improvement.
    Designed and implemented automated solutions to improve onboarding and documentation processes.
    Learned BASH scripting for better understanding and management of Azure Pipelines.
    Began creation of an Internal Developer Portal.
    Spearheaded organisation of our annual Hackathon.
    I've recently taken on a leadership role within the team as the team lead is currently absent. 
    <br />
    <br />
    <h2>Greggs, Technical Lead (Group Websites)</h2>
    <i>June 2022 - Jan 2024</i>
    <hr />
    Created the Greggs Engineering blog in my personal development time.
    Led development of the Careers, Corporate, and Foundation websites.
    Started Lightning Talks event, encouraging cross-collaboration.
    <br />
    <br />
    <h2>Greggs, Web Developer</h2>
    <i>Sept 2020 - June 2022</i>
    <hr />
    Helped develop the new Greggs.co.uk website, taking ownership of the new CMS.
    Developed the new Gifting journey.
    Created a Festive Bake live-sales Map in our first Hackathon.
    <br />
    <br />
    <h2>St Mary’s Calne, IT Technician</h2>
    <i>July 2018 - July 2019</i>
    <hr />
    Responsible for the upkeep and repair of school hardware.
    Provided technical troubleshooting and support to all members of staff and students.
    Worked with staff, students, and external speakers to assist in the setup and smooth running of events in the school chapel and assemblies.
      `,
  options: {
    speed: Speeds.MORE_TEXT,
  },
  sortOrder: 5,
} as ContentInterface

const hobbies = {
  content: `
      Well, I'm so glad you asked! Some of my favourite hobbies include:
      <ul>
        <li>Acrylic painting</li>
        <li>Weightlifting</li>
        <li>Embroidery</li>
        <li>Dogwalking</li>
        <li>Fiction writing (Self-published)</li>
        <li>Gaming (Tabletop and Video Games)</li>
        <li>Cooking</li>
        <li>Acoustic Guitar</li>
        <li>Gardening</li>
      </ul>
  
      <span style="display:flex;flex-direction:column;row-gap:10px">
      Check out my latest acrylic painting below:
      <img src="https://a.storyblok.com/f/111415/1848x2866/994d4648c4/latest_painting.jpg" alt="My latest acrylic painting, a dark-skinned woman with yellow hair, dressed in a purple witch hat and matching leotard. She is posing with a pumpkin in the style of a vintage pin-up poster."/>
      </span>
      `,
  options: {
    speed: Speeds.DEFAULT,
  },
  sortOrder: 20,
} as ContentInterface

const outreach = {
  content: `
      As a recovering theatre-kid, I'm often asked to speak at public events - and I enjoy doing so. Some of the events I've spoken at include:

      <h2>Lightning Talks</h2>
      <i>Ongoing</i><br/>
      <img src="https://a.storyblok.com/f/111415/3004x1696/1a340b0c7c/lightning-talk-2024.png/m/1920x0" alt="A screenshot of a slideshow including pictures of Moss Peteral farm, Katie Adams holding up a test tube of bog water, and her wellington boots in the peatland." /><br />
      Lightning Talks are my own creation; I developed them as a way for my colleagues and I to get to know each other away from the water cooler. Anybody can apply to give a 10 minute talk on just about anything. My Lightning Talks so far have been: 3D modelling using TinkerCAD, and National Bog Day. I plan to do another one where I will teach someone to play D&D in ten minutes.
      <br/>
      <br/>
      <h2>The Dynamites Awards</h2>
      <i>November 21st, 2024</i><br/>
      <img src="https://a.storyblok.com/f/111415/2000x1500/760573f865/dynamites-2024.jpg" alt="Two people on stage, one is Katie Adams stood in front of a podium with a small microphone. The icons for three local businesses are displayed behind her." /><br />
      Greggs had sponsored two awards at the Dynamites awards ceremony for 2024, and I had the honour of being asked to announce the winner of the Remarkable Innovation (Large Organisation) category. Sharing a stage with Suzi Ruffell was a particular highlight.
      <br/>
      <br/>
      <h2>Wordpress Wordcamp</h2>
      <i>July 12th, 2024</i><br/>
      <img src="https://a.storyblok.com/f/111415/2048x1536/1c71b9603d/wordpress-wordcamp.jpg" alt="Katie Adams and Josh Spoors stood in the Spanish City venue of Newcastle. Beside them is a presentation about the Greggs Tech Culture." /><br />
      Despite not being a Wordpress house, I was asked by an old friend to present something at the annual Wordcamp they were helping to organise in Whitley Bay. My buddy Josh and I put together a few slides to talk about the culture we strive for at Greggs TechEng and how we work to keep it friendly, warm, and inclusive.
      <br/>
      <br/>
      <h2>Storyblok Summit</h2>
      <i>June 12th 2024</i><br/>
      <img src="https://a.storyblok.com/f/111415/5215x3517/4193b639d3/storyblok-summit-2024.jpg/m/1920x0" alt="Katie Adams stood in front of a small audience. Behind her is a presentation talking about the web squad at Greggs." /><br />
      We were asked by our CMS partner, Storyblok, to attend and speak at their annual summit. We talked about our web tech stack, our Agile processes, and how we cultivated our culture within Greggs TechEng (we're pretty proud of it).
      <br/>
      <br/>
      <svg class="divider">
          <line x1="0" y1="0" x2="100%" y2="0" />
        </svg>
      I also thoroughly enjoy technical writing, applying my skills in fictional writing to the tech space. I'm in the process of creating a Tech Blog for our developers at Greggs, so watch this space to see what I've published. For my older work, you can check out my woefully underused blog on <a href="https://dev.to/katieadamsdev">Dev.to</a>.
      `,
  options: {
    speed: Speeds.DEFAULT,
  },
  sortOrder: 15,
} as ContentInterface

const skills = {
  content: `
      I'm always looking to learn new skills. However, I consider these my strongest ones:
      <ul>
        <li>HTML, CSS, Javascript</li>
        <li>Typescript</li>
        <li>Vue/Nuxt</li>
        <li>TailwindCSS</li>
        <li>Storybook</li>
        <li>Vitest/Jest</li>
        <li>Cypress</li>
        <li>Storyblok CMS</li>
        <li>Git</li>
        <li>Figma</li>
        <li>Power Automate</li>
        <li>Power Platform</li>
        <li>Azure DevOps</li>
        <li>Pixlr</li>
      </ul>
      
      Outside of tech, I am also known for my <strong>creativity</strong>, <strong>communication</strong>, and <strong>teamwork</strong>. My ability to translate technical jargon for stakeholders has earned me a good reputation with both my direct peers and other members of the workplace.
      In recent times, I've taken a more <strong>leadership-focused</strong> role and challenged myself in the process.
      `,
  options: {
    speed: Speeds.DEFAULT,
  },
  sortOrder: 15,
} as ContentInterface

export const content = {
  contact,
  default: home,
  education,
  employment,
  hobbies,
  skills,
  outreach,
} as { [key: string]: ContentInterface }
